/* Basic editor styles */
@use "sass:math";
@import "@styles/variables";

.reactComponent {
    position: relative;

    button {
        position: absolute;
        left: -60px;
        top: -8px;

        @media (max-width: ($tablet)) {
            margin-left: 0;
        }
    }

    .fileUpload {
        position: absolute;
        left: -30px;
        top: -5px;
        cursor: pointer;

    }

    .content {
        &_is_editable {
            border-radius: 0.5rem;
        }
    }
}