@use 'sass:math';
@import '@styles/variables';

.toast {
    padding: 11px;

    display: grid;
    align-items: center;
    column-gap: 12px;
    grid-template-columns: 32px 1fr;

    border-radius: 10px;
    border: 1px solid var(--Nl-Blue);
    background: var(--Brilliant-White);

    //.toast_success
    &_success {
        border: 1px solid var(--Shivering-Green);
        background: var(--Faint-Clover);

        .toast__icon {
            background: var(--Shivering-Green);
        }
    }

    //.toast_error
    &_error {
        border: 1px solid var(--Mandarin-Red);
        background: var(--Brides-Blush);

        .toast__icon {
            background: var(--Mandarin-Red);
        }
    }

    // .toast__body
    &__body {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }

    // .toast__icon
    &__icon {
        aspect-ratio: 1;

        border-radius: 50%;
        background: var(--Nl-Blue);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .toast__title
    &__title {
        font-weight: 600;
        line-height: 140%;
    }

    // .toast__text
    &__text {
        font-size: 14px;
        line-height: 140%;
    }
}
