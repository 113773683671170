@use "sass:math";
@import "@styles/variables";

.mobileNavLink {
    height: 48px;
    padding: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    //.mobileNavLink_active
    &_active {
        color: var(--Nl-Blue);
    }
}
