@import '@styles/variables';
@import '@styles/keyframes';


.reactionsWrapper {
    //position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
}

.selectedEmojis {
    display: flex;
    gap: 4px;
    //.selectedEmojis__item
    &__item {
        //.selectedEmojis__item_heart
        &_heart {
            order: -1;
        }
    }
}

.emojiIcon {
    display: flex;
    align-items: center;
    gap: 6px;

    & > span {
        padding-top: 1px;
    }
}

.selectedEmoji {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
}

.emojiCount {
    color: rgba(26, 23, 24, 0.8);
    //font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: center;
}

.button {
    position: relative;
    padding: 4px 8px;
    min-width: 36px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--Bleached-Silk);
    transition: all 0.3s ease;
}

.menuButtonWrapper {
    @media (min-width: ($second_mobile)) {
        position: relative;
    }
}

.emojiMenu {
    position: absolute;
    bottom: calc(100% + 6px);
    left: 0;
    background-color: var(--Bleached-Silk);
    border-radius: 6px;
    box-shadow: 0 4px 14.2px 0 var(--Thamar-Black-10);
    padding: 4px;
    pointer-events: none;

    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.2s ease, transform 0.4s ease;
    overflow: hidden;


    display: flex;
    align-items: center;
    gap: 4px;

    //.emojiMenu__open
    &__open {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
    }

    & > * {
        flex: 0 0 auto;
    }

    @media (max-width: ($second_mobile)) {
        bottom: calc(100% + 12px);
        transform: translate(-50%, 20px);
        left: 50%;
        &.emojiMenu__open {
            transform: translate(-50%, 0);
        }
    }
}

.emoji {
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.2);
    }
}
