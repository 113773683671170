@use "sass:math";
@import "@styles/variables";

.mobileNavigation {
    position: fixed;
    z-index: $ZIndexHeaderMobile;
    width: 100%;
    bottom: 0;
    border-radius: 16px 16px 0 0;
    background: var(--white-smoke);
    box-shadow: 0 4px 14px 0 var(--Thamar-Black-44);
    padding: 8px 0;

    //.mobileNavigation__container
    &__container {
    }

    //.mobileNavigation__grid
    &__grid {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
    }
}
