@import '@styles/variables';
@import '@styles/mixins';
@import '@styles/keyframes';

.nodeImage {
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 12px;
    height: 100%;
    position: relative;

    &__edit {
        cursor: pointer;

        :hover,
        :active {
            box-shadow: 0 0 0 2px white, 0 0 0 5px black;
            border-radius: 12px;
        }
    }

    &__clicked {
        border-radius: 12px;
        box-shadow: 0 0 0 2px white, 0 0 0 5px black;
    }


    &__delete {
        display: none;

        @media (max-width: ($mobile)) {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }
}