@use 'sass:math';
@import '@styles/variables';

.tooltip {
    padding: 12px !important;

    border-radius: 8px !important;
    color: var(--Wild-Dove) !important;
    background: var(--Bleached-Silk) !important;
    font-size: 13px !important;
    line-height: 140%;
    max-width: 352px;

    //.tooltip__arrow
    &__arrow {
        width: 17px !important;
        height: 17px !important;
        border-radius: 3px;
    }
}
