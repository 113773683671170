@use "sass:math";
@import "@styles/variables";

.avatar {
    display: inline-block;
    width: 100%;

    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 50%;
    position: relative;

    //.avatar_default
    &_default {
    }

    //.avatar__image,
    //avatar__loader
    &__image,
    &__loader {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        & > img {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    //.avatar__icon
    &__icon {
        width: 100%;
        height: 100%;
    }
}
