@use 'sass:math';
@import '@styles/variables';
@import '@styles/mixins';
@import '@styles/keyframes';

.postCard {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_bordered {
        border: 1.5px solid var(--White-Edgar);
    }

    @media (max-width: ($tablet)) {
        padding: 30px 24px;
    }

    @media (max-width: ($mobile)) {
        margin: 0 (-$containerPadding + px);
        padding: 24px 16px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 8px;
    }

    //.postCard__left
    &__left {
        display: flex;
        align-items: center;
        gap: 8px;

        &__avatar {
            width: 52px;
            height: 52px;
        }
    }

    //.postCard__name
    &__name {
        font-weight: 600;
        line-height: 140%;
        color: var(--Thamar-Black-80);
    }

    //.postCard__date
    &__date {
        font-size: 14px;
        color: var(--Wild-Dove);
    }

    //.postCard__share
    &__share {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: var(--Thamar-Black);
        word-break: break-word;
        margin-bottom: 10px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 15px;
        line-height: 140%;
        color: var(--Thamar-Black);
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        color: var(--Thamar-Black);
    }

    &__media {
        border-radius: 8px;
        align-self: stretch;
        aspect-ratio: 16 / 9;

        > picture > img {
            aspect-ratio: 16 / 9;
            border-radius: 8px;
        }
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        margin-top: auto;

        @media (max-width: ($second_mobile)) {
            position: relative;
        }

        &__right {
            display: flex;
            gap: 16px;
            align-items: center;
        }

        &__viewsAmount {
            z-index: 2;
            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--Steel-Wool);
        }
    }
}

.postCardList {
    $media: 1200px;

    display: grid;
    gap: 16px;
    border: 1.5px solid var(--White-Edgar);
    padding: 16px 24px;
    margin: 0 (-$containerPadding + px);

    @media (min-width: ($mobile)) {
        margin: 0;
        grid-template-columns: 1fr 397px;
        grid-template-rows: 46px;
        gap: 20px 16px;
        grid-template-areas:
            'header image'
            'content image'
            'footer empty';
        @media (max-width: $media) and (min-width: $tablet) {
            grid-template-columns: 1fr 260px;
        }
    }

    @media (max-width: ($tablet)) {
        padding: 30px 24px;
    }

    :global(.tiptap) {
        gap: 10px;
        display: -webkit-box;
        //-webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (max-width: $media) {
            //-webkit-line-clamp: 3;
        }
        @media (max-width: ($mobile)) {
            //-webkit-line-clamp: 2;
        }
    }

    //.box__footer
    &__footer {
        @media (min-width: ($mobile)) {
            grid-area: footer;
        }
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    h2 {
        font-size: 20px !important;
        line-height: 140%;
        font-weight: 600;
    }

    p {
        font-size: 16px !important;
    }

    /**
    * для карточек в списке мы подменяем h1 на h2
    * однако полностью убрать его нельзя, тк tiptap форсирует структуру
    * чтобы заголовок нельзя было удалить
    **/

    h1 {
        display: none;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 8px;

        @media (min-width: ($mobile)) {
            grid-area: header;
        }
    }

    //.postCard__left
    &__left {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 44px;

        &__avatar {
            width: 44px;
            height: 44px;
            max-width: 44px;
            max-height: 44px;
        }
    }

    //.postCard__user
    &__user {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    //.postCard__name
    &__name {
        font-weight: 600;
        line-height: 140%;
        color: var(--Thamar-Black-80);
    }

    //.postCard__date
    &__date {
        font-size: 14px;
        color: var(--Wild-Dove);
        line-height: 140%;
    }

    //.postCard__share
    &__share {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: var(--Thamar-Black);
        word-break: break-word;
        margin-bottom: 10px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 15px;
        line-height: 140%;
        color: var(--Thamar-Black);
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        color: var(--Thamar-Black);

        @media (min-width: ($mobile)) {
            &:not(:first-child) {
                margin-top: -4px;
            }

            grid-area: content;
        }
    }

    &__media {
        aspect-ratio: 16 / 9;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;

        @media (min-width: ($mobile)) {
            grid-area: image;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;

        & > a {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

.report {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: -1;
    display: none;

    &__active {
        opacity: 1;
        z-index: 999;
        transform: scale(1);
        display: block;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        &_title {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
        }

        &_close {
            background-color: var(--Bleached-Silk);
            padding: 12px;
            border-radius: 50%;
            cursor: pointer;
            padding: 8px;
        }
    }

    &__content {
        background-color: var(--white);
        padding: 24px 32px;
        border-radius: 12px;
    }

    &__body {
        display: flex;
        flex-direction: column;

        &_title {
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            margin-bottom: 8px;
        }

        &_text {
            font-size: 13px;
            font-weight: 400;
            line-height: 18.2px;
            margin-bottom: 18px;
        }

        &_list {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-bottom: 24px;

            &_item {
                display: flex;
                align-items: center;
            }
        }

        &_buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: 24px;

            &_cancel {
                border: 1.5px solid var(--Steel-Wool);
            }
        }
    }
}

.actions {
    position: relative;

    & button {
        user-select: none;
    }

    &__icon {
        cursor: pointer;
        transform: rotate(90deg);
    }

    &__icon_delete {
        color: var(--Mandarin-Red);
    }

    &__list {
        position: absolute;
        z-index: 100;
        top: calc(100% + 11px);
        right: 0;
        min-width: 170px;
        width: max-content;

        padding: 12px;
        border-radius: 12px;
        background: var(--white);
        box-shadow: 0 4px 14.2px 0 var(--Thamar-Black-10);

        display: flex;
        flex-direction: column;
        row-gap: 4px;

        opacity: 0;
        pointer-events: none;
        transform: translateY(-20px);

        transition: opacity 0.3s ease 0s, transform 0.3s ease 0s, pointer-events 0.3s ease 0s;

        //.actions__list_open
        &_open {
            opacity: 1;
            transform: translateY(0px);
            pointer-events: fill;
        }

        & > button {
            text-align: left;
            justify-content: flex-start;
        }
    }
}

.block {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: -1;
    display: none;

    &__active {
        opacity: 1;
        z-index: 999;
        transform: scale(1);
        display: block;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        &_title {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
        }

        &_close {
            background-color: var(--Bleached-Silk);
            padding: 12px;
            border-radius: 50%;
            cursor: pointer;
            padding: 8px;
        }
    }

    &__content {
        background-color: var(--white);
        padding: 24px 32px;
        border-radius: 12px;
    }

    &__body {
        display: flex;
        flex-direction: column;

        &_title {
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            margin-bottom: 8px;
        }

        &_text {
            font-size: 13px;
            font-weight: 400;
            line-height: 18.2px;
            margin-bottom: 18px;
        }

        &_list {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-bottom: 24px;

            &_item {
                display: flex;
                align-items: center;
            }
        }

        &_buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: 24px;

            &_cancel {
                border: 1.5px solid var(--Steel-Wool);
            }
        }
    }
}
