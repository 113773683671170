@use "sass:math";
@import "@styles/variables";
@import "@styles/keyframes";

.uiLink {
    position: relative;

    min-height: 28px;
    padding: 4px 0;

    display: inline-flex;
    align-items: center;
    gap: 2px;

    & > span {
        padding: 0 4px;
        color: var(--Thamar-Black);
        font-size: 14px;
        line-height: 140%;
    }

    & > svg {
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
    }

    //.uiLink_Thin
    &_Thin {

    }

    //.uiLink_Regular
    &_Regular {
        font-weight: 500;
    }

    //.uiLink_Thick
    &_Thick {
        font-size: 16px;
        font-weight: 600;
    }


    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 1.5px;
        bottom: 0;
        right: 0;
        background-color: var(--Thamar-Black);
        transition: width 0.3s ease 0s;
    }


    //.uiLink_active
    &_active {
        &:before {
            width: 100%;
        }
    }

    //.uiLink_loading
    &_loading {
        cursor: wait;
        animation: pulse 1.5s infinite ease-in-out;

    }

    //.uiLink_disabled
    &_disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:not(.uiLink_active):not(.uiLink_disabled) {
        @media (any-hover: hover) {
            &:hover {
                &:before {
                    left: 0;
                    width: 100%;
                }
            }
        }
    }

    &:focus-visible {
        border-radius: 6px;
        outline: 1.5px solid var(--Thamar-Black);
    }
}
