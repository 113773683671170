@use "sass:math";
@import "@styles/variables";

.header {
    position: sticky;
    z-index: $ZIndexHeader;
    top: 0;
    left: 0;

    padding: 16px 0;
    background: var(--white);

    @media (max-width: ($mobile)) {
        border-bottom: 1px solid var(--Thamar-Black-10);
    }

    @media (max-width: ($mobile)) {
        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: inherit;
        }
    }


    // .header__body
    &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 16px;
    }

    // .header__left
    &__left {
        display: grid;
        grid-template-columns: 64px 1fr;
        align-items: center;
        column-gap: 40px;

        @media (max-width: ($tablet)) {
            column-gap: 16px;
        }

        @media (max-width: ($mobile)) {
            grid-template-columns: 36px 1fr;
            column-gap: 10px;
        }

        //.header__left_back
        &_back {
            @media (max-width: ($mobile)) {
                grid-template-columns: 36px 36px 1fr;
            }
        }
    }

    // .header__logo
    &__logo {
        width: 64px;
        height: 64px;
        @media (max-width: ($mobile)) {
            width: 36px;
            height: 36px;
        }
    }

    // .header__navigation
    &__navigation {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 36px;
    }

    // .header__right
    &__right {
        display: flex;
        align-items: center;
        column-gap: 40px;

        & > * {
            flex: 0 0 auto;
        }

        @media (max-width: ($tablet)) {
            column-gap: 16px;
        }

        @media (min-width: ($mobile)) {
            position: relative;
        }
    }

    // .header__actions
    &__actions {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        column-gap: 20px;
        @media (max-width: ($tablet)) {
            column-gap: 16px;
        }
    }

    // .header__account
    &__account {
        cursor: pointer;

        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        column-gap: 8px;

        //.header__account_open
        &_open {
            & > svg {
                transform: rotate(180deg);
            }
        }
    }

    //.header__avatar
    &__avatar {
        width: 48px;
        aspect-ratio: 1;
    }

    //.header__burger
    &__burger {
        cursor: pointer;

        width: 36px;
        aspect-ratio: 1/1;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}
