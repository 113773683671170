.container {
    padding: 24px;
    width: 100%;
    max-width: 480px;
    background: var(--white);
    border-radius: 12px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &_title {
        font-size: 24px;
        font-weight: 600;
        color: var(--black);
    }

    &_subtitle {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
        color: var(--black);
    }

    &_close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: var(--gray-100);
        cursor: pointer;
        transition: background 0.2s ease-in-out;

        &:hover {
            background: var(--gray-200);
        }
    }
}

.body {
	color: var(--Thamar-Black);

    &_title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

	&_subtitle {
		font-size: 16px;
		margin-bottom: 8px;
		font-weight: 400;
	}

    &_text {
        display: block;
        font-size: 13px;
        margin-bottom: 18px;
		line-height: 18px;
    }

	&_question {
		font-size: 16px;
		margin-bottom: 18px;
		line-height: 22px;
	}

    &_list {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
		gap: 12px;

        &_item {
            display: flex;
            align-items: center;
            border-radius: 8px;
            cursor: pointer;
			line-height: 22px;
			font-weight: 500;
            transition: background 0.2s ease-in-out;

            &:hover {
                background: var(--gray-100);
            }
        }
    }

    &_buttons {
        display: flex;
        gap: 12px;
        margin-top: 24px;

        &_cancel {
            color: var(--gray-600);
			border: 1px solid var(--Thamar-Black);
        }
    }
} 