@use "sass:math";
@import "@styles/variables";

.container {
	background: var(--white);
	border-radius: 12px;
	padding-block: clamp(1rem, calc(0.885rem + 0.513vw), 1.5rem);
	padding-inline: clamp(0.75rem, calc(0.462rem + 1.282vw), 2rem);

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;

		&_title {
			font-size: 28px;
			font-weight: 600;
			line-height: 34px;
			color: var(--Thamar-Black);
		}

		&_close {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px;
			background: var(--Bleached-Silk);
			border-radius: 50%;
		}
	}

	&__text {
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		color: var(--Thamar-Black);
		margin-bottom: 24px;
	}

	&__buttons {
		margin-top: 24px;
		display: flex;
		gap: 16px;

		@media (max-width: 425px) {
			flex-direction: column;

			& > button {
				flex: 1;
			}
		}

		&_cancel {
			border: 1px solid var(--Thamar-Black);
		}
	}
}