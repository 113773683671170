@use "sass:math";
@import "@styles/variables";

.selectBox {

    //.selectBox__label
    &__label {
        font-weight: 500;
        line-height: 140%;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

}