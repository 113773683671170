@use "sass:math";
@import "@styles/variables";

.footer {
    @media (max-width: ($mobile)) {
        margin-bottom: 64px;
    }

    // .footer__body
    &__body {
        margin: 0 (-$containerPadding + px);
        @media (max-width: ($mobile)) {
            box-shadow: 0 0 0 1px var(--Jupiter);
            margin-bottom: 32px;
        }
    }

    // .footer__block
    &__block {
        padding: 12px 16px;

        @media (min-width: ($mobile)) {
            padding: 48px;
        }
    }
}

.footerTelegram {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media (max-width: ($mobile)) {
        align-items: center;
        padding: 24px 16px;
    }

    @media (min-width: ($mobile)) {
        box-shadow: 0 0 0 0.5px var(--Jupiter);
    }

    //.footerTelegram__bg
    &__bg {
        aspect-ratio: 2;

        @media (min-width: ($mobile)) {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
        }

        @media (max-width: ($tablet)) {
            width: 125%;
        }

        @media (max-width: ($mobile)) {
            width: 328px;
        }
    }

    // .footerTelegram__body
    &__body {
        max-width: 732px;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: auto;
        row-gap: 32px;

        @media (max-width: ($mobile)) {
            align-items: center;
        }

    }

    // .footerTelegram__title
    &__title {
        font-family: $fontPlatformLC;
        font-size: 44px;
        font-weight: 500;
        line-height: 120%;
        z-index: 2;

        @media (max-width: ($mobile)) {
            text-align: center;
            font-size: 24px;
        }
    }

    // .footerTelegram__row
    &__row {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        column-gap: 16px;
        z-index: 2;

        max-width: 460px;
        width: 100%;
    }
}

.footerBottom {
    @media (max-width: ($mobile)) {
        padding: 0 0 24px;
    }

    // .footerBottom__box
    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;

        @media (max-width: ($tablet)) {
            row-gap: 20px;
        }
    }

    // .footerBottom__socials
    &__socials {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: ($tablet)) {
            align-self: stretch;

            display: grid;
            grid-template-columns: repeat(auto-fit, 72px);
            justify-content: center;
            //margin: 0 (-$containerPadding - 1 + px);
        }
    }

    // .footerBottom__item
    &__item {
        aspect-ratio: 1;
        box-shadow: inset 0 0 0 1px var(--Jupiter);

        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: ($tablet)) {
            flex: 0 0 48px;
            width: 48px;
        }
    }

    // .footerBottom__link
    &__link {
        flex: 0 0 auto;
        padding: 0 4px;

        font-size: 14px;
        line-height: 140%;
    }

    // .footerBottom__copyright
    &__copyright {
        flex: 0 0 auto;
        font-size: 14px;
        line-height: 140%;

        span {
            vertical-align: middle;
            padding: 0 4px;
        }

        @media (max-width: ($tablet)) {
            flex: 1 1 100%;
            order: 2;
        }
    }
}
