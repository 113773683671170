/* Basic editor styles */
@use "sass:math";
@import "@styles/variables";


.bubbleMenu {
    background-color: var(--Bleached-Silk);
    border-radius: 0.7rem;
    display: flex;
    gap: 8px;
    padding: 5px;

    @media (max-width: ($mobile)) {
        display: none;
    }

    &__item {
        border-radius: 6px;
        padding: 12px;
        width: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &_active {
            box-shadow: 0 0 0 2px var(--Silver-Lake);
            background-color: var(--Unicorn-Silver);
        }

        transition: all 0.2s ease-in-out;
    }

    &__linkInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 8px;
        width: 184px;
        font-size: 13px;

        input {
            width: 100%;
        }

        button {
            width: auto;
        }
    }

    &__divider {
        border-left: 1px solid var(--Silver-Lake);
        height: 35px;
    }
}